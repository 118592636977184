import React from 'react';

import { AppStoreButtons } from '../../../components/Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { Container } from '../../../components/Container/Container';
import { Grid } from '../../../components/Grid/Grid';
import { Heading } from '../../../components/Heading/Heading';
import { Image } from '../../../components/Image/Image';
import { QRCode } from '../../../components/QRCode/QRCode';
import { Text } from '../../../components/Text/Text';
import heroDesktop from '../assets/heroDesktop.png';
import heroMobile from '../assets/heroMobile.png';
import heroTablet from '../assets/heroTablet.png';

interface IShopLandingPageHeader {
  appPage?: boolean;
}

export const ShopLandingHeader = ({ appPage = false }: IShopLandingPageHeader): JSX.Element => (
  <header>
    <div className="mx-auto flex max-w-screen-lg flex-col sm:grid sm:grid-cols-1 sm:grid-rows-1">
      <div className="col-start-1 row-start-1 w-full object-cover">
        <Image
          src={heroDesktop.src}
          alt="Hero banner image"
          className="w-full object-cover"
          loading="eager"
        >
          <source srcSet={heroMobile.src} media="(max-width: 599px)" />
          <source srcSet={heroTablet.src} media="(max-width: 769px)" />
        </Image>
      </div>

      <Container className="mb-sm sm:col-start-1 sm:row-start-1">
        <Grid>
          <div className="col-start-1 col-end-5 mt-4 flex flex-col sm:col-end-11 sm:mt-8 md:col-start-2 md:col-end-10 md:mt-10">
            <Heading
              as="h1"
              variant="lg"
              className="mt-4 text-center sm:text-left sm:text-sea-foam"
            >
              Our marketplace may be closed, but we’ve still got you covered.
            </Heading>

            <Text variant="md" className="text-center sm:text-left sm:text-sea-foam">
              The right bait can change from day to day, but with 10,000 new catches added daily
              Fishbrain can give you insight into which lure is catching fish in your area right
              now. We are even partnering with local tackle shops to show you the closest place
              selling that hot bait.
            </Text>

            {!appPage && (
              <div className="flex items-center justify-center sm:mt-2 sm:justify-start">
                <QRCode className="sm:mr-4" source="ShopLandingHeader" />
                <AppStoreButtons source="ShopLandingHeader" orientation="vertical" />
              </div>
            )}
          </div>
        </Grid>
      </Container>
    </div>
  </header>
);
