import React from 'react';

import { ExternalLink } from '../../../components/Clickables/ExternalLink/ExternalLink';
import { Container } from '../../../components/Container/Container';
import { Heading } from '../../../components/Heading/Heading';
import { Text } from '../../../components/Text/Text';
import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../helpers/analytics/trackEvent';

export const ShopLandingQuestion = (): JSX.Element => {
  const { currentPage } = useAnalytics();
  const onClick = (): void => {
    void trackEvent({
      name: 'mykiss_cta_button_clicked',
      properties: {
        page: currentPage,
        source: 'bait-and-lure',
        type: 'read-more',
      },
    });
  };

  return (
    <Container className="my-md">
      <Heading as="h2" variant="md" className="md:w-col-8 mb-4 text-center md:mx-auto">
        Do you have a question about a past order placed on Fishbrain.com?
      </Heading>
      <Text variant="md" className="md:w-col-8 text-center md:mx-auto">
        If you have any concerns regarding returns, refunds, or any other inquiries related to past
        purchases, please do not hesitate to contact us at&nbsp;{' '}
        <ExternalLink href="mailto:shop@fishbrain.com" className="underline" onClick={onClick}>
          Shop@fishbrain.com
        </ExternalLink>
      </Text>
      <Text variant="md" className="md:w-col-8 text-center md:mx-auto">
        Our dedicated team is available to assist you with all your needs.
      </Text>
    </Container>
  );
};
