import React from 'react';

import { twMerge } from 'tailwind-merge';

import { useIsDesktopScreenWidthOrWider } from '../../../helpers/hooks/useScreenWidth';

import { Pill } from './Pill';

export const AppOnlyPill = ({ className }: { className?: string }): JSX.Element => {
  const isDesktopScreenWidthOrWider = useIsDesktopScreenWidthOrWider();
  return (
    <div className={twMerge('mb-3', className)}>
      <Pill
        variant="infoBlue"
        disabled
        size={`${isDesktopScreenWidthOrWider ? 'lg' : 'sm'}`}
        className="font-semibold"
      >
        App only
      </Pill>
    </div>
  );
};
