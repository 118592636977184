import React, { ReactNode } from 'react';

import { useIsSafari } from '../../helpers/hooks/useIsSafari';

export interface IImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string;
  alt: string;
  avifSrc?: string;
  webPSrc?: string;
  children?: ReactNode;
  pictureProps?: JSX.IntrinsicElements['picture'];
}

export const Image = ({
  avifSrc,
  webPSrc,
  src,
  alt,
  children,
  pictureProps,
  ...props
}: IImageProps): JSX.Element => {
  const isSafari = useIsSafari();
  // Since avif images are not supported by older safari versions need to add this condition - (avifSrc && !isSafari)
  // Usually it should fallback to the normal image, but for some reason it does not work
  // Review this condition later
  if (children || (avifSrc && !isSafari) || webPSrc) {
    return (
      <picture {...pictureProps}>
        {children}
        {avifSrc && <source type="image/avif" srcSet={avifSrc} />}
        {webPSrc && <source type="image/webp" srcSet={webPSrc} />}
        <img src={src} alt={alt} {...props} />
      </picture>
    );
  }
  return <img src={src} alt={alt} {...props} />;
};
