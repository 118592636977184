import React from 'react';

import { twMerge } from 'tailwind-merge';

const SHARED_CLASSES = 'ring-inset focus:ring focus:outline-none';
const SHARED_ACTIVE_CLASSES =
  'bg-deep-sea text-white hover:bg-trench focus:ring-trench active:bg-deep-sea-active';
const DISABLED_CLASSES = 'bg-white border border-disabled text-disabled cursor-not-allowed';
const VARIANTS = {
  whiteBg: {
    inactive:
      'bg-lake-fog text-deep-sea hover:text-trench hover:bg-white focus:ring-twilight active:bg-white active:text-deep-sea-active',
    active: SHARED_ACTIVE_CLASSES,
    disabled: DISABLED_CLASSES,
  },
  lakeFogBg: {
    active: SHARED_ACTIVE_CLASSES,
    inactive:
      'bg-white text-deep-sea hover:text-trench hover:bg-lake-fog focus:ring-twilight active:bg-lake-fog active:text-deep-sea-active',
    disabled: DISABLED_CLASSES,
  },
  deepSea: {
    inactive: 'bg-deep-sea text-sea-foam hover:bg-lake-fog hover:text-deep-sea',
    active: 'bg-deep-sea text-sea-foam hover:bg-lake-fog hover:text-deep-sea',
    disabled: 'bg-deep-sea text-sea-foam hover:bg-lake-fog hover:text-deep-sea',
  },
  twilight: {
    active: SHARED_ACTIVE_CLASSES,
    inactive:
      'bg-twilight text-deep-sea hover:text-trench hover:bg-midnight focus:ring-midnight active:bg-lake-fog active:text-deep-sea-active',
    disabled: DISABLED_CLASSES,
  },
  grey: {
    active: 'bg-midnight bg-opacity-40',
    inactive: 'text-trench bg-twilight hover:bg-midnight hover:bg-opacity-30',
    disabled: DISABLED_CLASSES,
  },
  pink: {
    active: 'text-sea-foam bg-pinktail bg-opacity-50',
    inactive: 'text-sea-foam bg-pinktail-shade hover:bg-pinktail hover:bg-opacity-60',
    disabled: DISABLED_CLASSES,
  },
  pinkLight: {
    active: 'text-pinktail-shade bg-pinktail bg-opacity-40',
    inactive: 'text-pinktail-shade bg-pinktail-tint hover:bg-opacity-30 hover:bg-pinktail',
    disabled: DISABLED_CLASSES,
  },
  error: {
    active: 'text-error-shade bg-error bg-opacity-40',
    inactive:
      'bg-error-tint text-error-shade hover:bg-error hover:bg-opacity-30 focus:bg-error focus:bg-opacity-40 active:bg-error active:bg-opacity-40',
    disabled: 'border border-disabled text-disabled cursor-not-allowed',
  },
  infoBlue: {
    active: 'hidden',
    inactive: 'hidden',
    disabled: 'bg-tuna-tint text-tuna-shade border-disabled cursor-not-allowed',
  },
  infoClown: {
    active: 'hidden',
    inactive: 'hidden',
    disabled: 'bg-clownfish-tint text-clownfish-shade border-disabled cursor-not-allowed',
  },
  infoGrey: {
    active: 'hidden',
    inactive: 'hidden',
    disabled: 'bg-twilight text-midnight font-semibold',
  },
  infoPro: {
    active: 'hidden',
    inactive: 'hidden',
    disabled: 'bg-pike text-trench',
  },
  infoProLight: {
    active: 'hidden',
    inactive: 'hidden',
    disabled: 'bg-pike-tint text-pike-shade',
  },
};

const SIZES = {
  lg: 'px-4 py-[13.5px] text-sm font-semibold rounded-[70px]',
  sm: 'px-3 py-2 text-[13px] rounded-[43px]',
  thin: 'px-4 pb-1 pt-1.5 rounded-full h-fit ',
};

export type Variant = keyof typeof VARIANTS;
type Size = keyof typeof SIZES;

export interface IPillProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: string | React.ComponentType<any> | React.FunctionComponent<any>;
  isActive?: boolean;
  variant?: Variant;
  size?: Size;
  dataTestId?: string;
}

function getVariantType(disabled: boolean, isActive: boolean): 'disabled' | 'active' | 'inactive' {
  if (disabled) {
    return 'disabled';
  }
  return isActive ? 'active' : 'inactive';
}

export const Pill = ({
  as: Tag = 'button',
  className = '',
  disabled = false,
  isActive = false,
  size = 'lg',
  variant = 'whiteBg',
  dataTestId,
  ...rest
}: IPillProps): JSX.Element => {
  const variantType = getVariantType(disabled, isActive);
  const computedClassname = twMerge(
    SHARED_CLASSES,
    SIZES[size],
    VARIANTS[variant][variantType],
    className,
  );
  return (
    <Tag disabled={disabled} className={computedClassname} data-testid={dataTestId} {...rest} />
  );
};
