import React from 'react';

import { InternalLink } from '../../../components/Clickables/InternalLink/InternalLink';
import { AppOnlyPill } from '../../../components/Clickables/Pill/AppOnlyPill';
import { Container } from '../../../components/Container/Container';
import { Heading } from '../../../components/Heading/Heading';
import { ArrowRightIcon } from '../../../components/Icons/ArrowRightIcon';
import { Image } from '../../../components/Image/Image';
import { Text } from '../../../components/Text/Text';
import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../helpers/analytics/trackEvent';
import allCatchesDesktop from '../assets/allCatchesDesktop.png';
import allCatchesMobile from '../assets/allCatchesMobile.png';
import allCatchesTablet from '../assets/allCatchesTablet.png';

export const ShopLandingFeature = (): JSX.Element => {
  const { currentPage } = useAnalytics();
  const onClick = (): void => {
    void trackEvent({
      name: 'mykiss_cta_button_clicked',
      properties: {
        page: currentPage,
        source: 'support-email',
        type: 'read-more',
      },
    });
  };

  return (
    <div className="mx-auto max-w-screen-lg">
      <Container className="mb-sm sm:mt-sm flex flex-col-reverse sm:flex-row-reverse sm:items-center sm:justify-center md:justify-around">
        <div className="sm:w-col-6 md:w-col-5 mt-6">
          <AppOnlyPill />
          <Heading as="h2" variant="md">
            Bait and lure recommendations
          </Heading>
          <Text variant="md">
            Find the top baits for your target species and area. Make sure your tackle box is
            stocked with essentials based on data from 15 million catches
          </Text>

          <InternalLink
            to="/features"
            className="py-6 pr-6 font-heading text-pinktail hover:text-pinktail-shade"
            onClick={onClick}
          >
            Read more
            <div className="ml-0.5 inline-block">
              <ArrowRightIcon width={20} height={20} />
            </div>
          </InternalLink>
        </div>

        <div className="sm:w-col-5 md:w-col-4 mt-4 md:mt-0">
          <Image
            src={allCatchesDesktop.src}
            alt="See top baits in the app"
            className=""
            loading="lazy"
          >
            <source srcSet={allCatchesMobile.src} media="(max-width: 599px)" />
            <source srcSet={allCatchesTablet.src} media="(max-width: 769px)" />
          </Image>
        </div>
      </Container>
    </div>
  );
};
